import axios from "axios";
import router from "@/router";
import { useUserStore } from "@/stores/user";
import routesName from "@/utils/constants/routesName";
import authApi from "@/services/api/authApi";

interface Subscriber {
  (token: string): void;
}

const baseURL = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : ``;
const axiosClient = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
let subscribers: Subscriber[] = [];
let isRefreshing = false;

function onRefreshed(accessToken: string): void {
  subscribers.forEach((cb) => cb(accessToken));
}

function subscribeTokenRefresh(cb: Subscriber): void {
  subscribers.push(cb);
}

axiosClient.interceptors.request.use(
  (request) => {
    const userStore = useUserStore();
    if (request.headers && userStore.accessToken) {
      request.headers.Authorization = `Bearer ${userStore.accessToken}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const userStore = useUserStore();
    const originalRequest = error.config;
    const refreshToken: string | null = userStore.refreshToken;
    if (error.response.status === 401) {
      if (error.config.url === "auth/refresh") {
        userStore.clear();
        router.push({ name: routesName.login });
      }
      if (refreshToken) {
        if (!isRefreshing) {
          isRefreshing = true;
          authApi
            .refreshToken({
              access_token: userStore.accessToken,
              refresh_token: userStore.refreshToken,
            })
            .then((res) => {
              userStore.setTokens({
                accessToken: res.data.data.access_token,
                refreshToken: res.data.data.refresh_token,
              });
              isRefreshing = false;
              onRefreshed(res.data.data.access_token);
              subscribers = [];
            })
            .catch(() => {
              userStore.clear();
              router.push({
                name: routesName.login,
                query: { redirect: error.config.url },
              });
            });
        }
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axiosClient(originalRequest));
          });
        });
      } else {
        userStore.clear();
        router.push({
          name: routesName.login,
          query: { redirect: error.config.url },
        });
      }
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
